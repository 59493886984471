@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
:root {
  margin: 0;
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.navbar {
    /* background: transparent; */
    background: rgba(255, 255, 255, 0.85);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    transition: box-shadow 0.3s ease;
}

/* change nav bg color on scroll */
.navbar.active {
    /* background: #1fb4ffd6; */

    box-shadow: 0 2px 10px 0 #00000024;
}


/* change nav bg color when dropdown is open */
.navbar.isOpen {
    background: white;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
}

.navLogoText {
    color: #1FB6FF;
    padding-left: 15px;
    margin-left: 15px;
    border-left: 2px solid #1FB6FF;
    height: 32px;
    font-size: 24px;
    font-weight: 500;
    pointer-events: none;
}
.card {
  transition: all 0.3s ease-in-out;
}

.card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
}

.logos {
  overflow: hidden;
  display: inline-flex;
  white-space: nowrap;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  content: "";
  z-index: 1;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, transparent, #f4f4f4);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, transparent, #f4f4f4);
}

.logos-slider-right,
.logos-slider-left {
  display: inline-flex;
  align-items: center;
}

.logos-slider-right img,
.logos-slider-left img {
  margin: 0 40px;
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
}

.logos-slider-right {
  -webkit-animation: 35s slide-right infinite linear;
          animation: 35s slide-right infinite linear;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.logos-slider-left {
  -webkit-animation: 35s slide-left infinite linear;
          animation: 35s slide-left infinite linear;
}

@-webkit-keyframes slide-right {
  from {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slide-right {
  from {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slide-left {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}

@keyframes slide-left {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}

@media (max-width: 900px) {
  .logos {
    width: 100vw;
  }

  .logos-slider-left {
    -webkit-animation: 15s slide-left infinite linear;
            animation: 15s slide-left infinite linear;
  }

  .logos-slider-right {
    -webkit-animation: 15s slide-right infinite linear;
            animation: 15s slide-right infinite linear;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}

.lottieContainer {
  > svg {
    -webkit-transform: unset !important;
            transform: unset !important;
  }
}

.form {
  justify-content: center;
  margin-right: 30px;
}

.form input,
textarea {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 12px;
  border-radius: 6px;
  border: 2px solid #0f5a80;
  font-family: Montserrat;
  font-size: 16px;
}

.form input:focus {
  border: 2px solid #1fb6ff !important;
  outline: #1a9dde;
}

.form textarea {
  resize: none !important;
  height: 100px;
}

/* error messages */
.form ul {
  margin-top: auto;
  color: #ff6473;
}

.form input[type="submit"] {
  border-radius: 30px;
  border-color: #1fb6ff;
  background-color: #1fb6ff;
  color: white;
  font-weight: 600;
  width: auto;
  padding: 16px 70px;
}

.form input[type="submit"]:hover {
  background-color: #1a9dde;
  border-color: #1a9dde;
  cursor: pointer;
  font-size: 16px;
}

.actions {
  text-align: center;
  padding-left: 30px;
}

