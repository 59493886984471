.form {
  justify-content: center;
  margin-right: 30px;
}

.form input,
textarea {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 12px;
  border-radius: 6px;
  border: 2px solid #0f5a80;
  font-family: Montserrat;
  font-size: 16px;
}

.form input:focus {
  border: 2px solid #1fb6ff !important;
  outline: #1a9dde;
}

.form textarea {
  resize: none !important;
  height: 100px;
}

/* error messages */
.form ul {
  margin-top: auto;
  color: #ff6473;
}

.form input[type="submit"] {
  border-radius: 30px;
  border-color: #1fb6ff;
  background-color: #1fb6ff;
  color: white;
  font-weight: 600;
  width: auto;
  padding: 16px 70px;
}

.form input[type="submit"]:hover {
  background-color: #1a9dde;
  border-color: #1a9dde;
  cursor: pointer;
  font-size: 16px;
}

.actions {
  text-align: center;
  padding-left: 30px;
}
