.navbar {
    /* background: transparent; */
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(15px);
    transition: box-shadow 0.3s ease;
}

/* change nav bg color on scroll */
.navbar.active {
    /* background: #1fb4ffd6; */

    box-shadow: 0 2px 10px 0 #00000024;
}


/* change nav bg color when dropdown is open */
.navbar.isOpen {
    background: white;
    backdrop-filter: none;
}

.navLogoText {
    color: #1FB6FF;
    padding-left: 15px;
    margin-left: 15px;
    border-left: 2px solid #1FB6FF;
    height: 32px;
    font-size: 24px;
    font-weight: 500;
    pointer-events: none;
}