.logos {
  overflow: hidden;
  display: inline-flex;
  white-space: nowrap;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  content: "";
  z-index: 1;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, transparent, #f4f4f4);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, transparent, #f4f4f4);
}

.logos-slider-right,
.logos-slider-left {
  display: inline-flex;
  align-items: center;
}

.logos-slider-right img,
.logos-slider-left img {
  margin: 0 40px;
  transform: scale(0.75);
}

.logos-slider-right {
  animation: 35s slide-right infinite linear;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.logos-slider-left {
  animation: 35s slide-left infinite linear;
}

@keyframes slide-right {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100vw);
  }
}

@media (max-width: 900px) {
  .logos {
    width: 100vw;
  }

  .logos-slider-left {
    animation: 15s slide-left infinite linear;
  }

  .logos-slider-right {
    animation: 15s slide-right infinite linear;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}
